import {graphql, HeadFC, PageProps} from 'gatsby';
import {CMSData} from '../@types/CMSData';
import * as React from 'react';
import ProjectsPage from '../components/ProjectsPage';
import Seo from '../components/Seo';

const Projects = ({data}: PageProps<CMSData>) => {
  return (
    <ProjectsPage projects={data.allProject.nodes} />
  );
};

export default Projects;

export const Head: HeadFC = () => <Seo title={'Projekti | Žvan Custom Campers'} sitePath={'/projekti'} description={'Opis in galerija slik dokončanih projektov in predelav.'}/>;

export const query = graphql`
  query ProjectsPageQuery {
    allProject {
      nodes {
        title
        description
        images
        order
        isFeatured
        featuredImage
      }
    }
  }
`;
