import SkewedPanel from './SkewedPanel';
import * as React from 'react';
import {Container, Grid, Typography} from '@mui/material';
import ProjectDetails from './ProjectDetails';
import {Project} from '../@types/CMSData';

export default function ProjectsPage({projects}: {projects: Project[]}) {
  return (
    <>
      <SkewedPanel variant={'dark'} noTopMargin={true} id={'vsi'}>
        <Container><Typography align={'center'} variant={'h1'} color={'white'}>Naši projekti</Typography></Container>
      </SkewedPanel>
      <Container sx={{pt: '50px'}}>
        <Grid container direction={'column'} spacing={6}>
          {projects.sort((a, b) => b.order - a.order).map((project, i) => (
            <Grid key={i} item xs={12}><ProjectDetails project={project} /></Grid>))}
        </Grid>
      </Container>
    </>);
}
