import React, {useRef} from 'react';
import {Project} from '../@types/CMSData';
import {Box, Grid, Typography} from '@mui/material';
import useMobile from './useMobile';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import BulletPointIcon from '../assets/bullet-point-icon.svg';
import CollectionsIcon from '@mui/icons-material/Collections';
import MuiMarkdown from 'mui-markdown';
import useCloudStorageImage from '../hooks/useCloudStorageImage';

function ProjectDetails({project}: {project: Project}) {
  const isMobile = useMobile();
  const lightGallery = useRef<any>();
  const createImageUrl = useCloudStorageImage();
  const bulletSize = isMobile ? '1.4rem' : '1.6rem';
  return (
    <>
      <LightGallery plugins={[lgZoom]} mode='lg-fade' onInit={(detail) => {
        if (detail) {
          lightGallery.current = detail.instance;
        }
      }} dynamic={true} dynamicEl={project.images.map((srcUrl) => ({
        src: createImageUrl(srcUrl),
      }))} />
      <Grid container direction={'row'} spacing={isMobile ? 2 : 5} id={project.title}>
        <Grid item xs={12} md={5} order={isMobile ? 2 : 1}>
          <Grid container direction={'column'}>
            <Grid item xs={12} sx={{overflow: 'hidden', borderRadius: '10px'}}>
              <Box
                sx={{
                  height: '300px', cursor: 'pointer', borderRadius: '10px',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundImage: `url('${createImageUrl(project.featuredImage)}')`,
                  transition: 'transform .1s',
                  '&:hover': {
                    transform: 'scale(0.995)',
                  },
                }}
                onClick={() => lightGallery.current.openGallery()}>
                <Grid container alignItems={'flex-end'} justifyContent={'center'} sx={{height: '100%'}}>
                  <Grid item sx={{p: 2}}>
                    <Typography variant={'body2'} fontWeight={500}
                                color={{}}
                                sx={{
                                  backgroundColor: 'rgba(255,255,255,0.9)',
                                  borderRadius: '5px',
                                  p: '5px 20px',
                                }}
                                onClick={() => lightGallery.current.openGallery()}>Galerija slik<CollectionsIcon sx={{
                      verticalAlign: 'middle',
                      display: 'inline-flex',
                      ml: 1,
                      mr: 0.3,
                    }} /><Typography variant={'caption'}
                                     fontWeight={600}>{project.images.length}</Typography></Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7} order={isMobile ? 1 : 2}>
          <Grid container spacing={2} direction={'column'}>
            <Grid item>
              <Grid container wrap={'nowrap'} alignItems={'center'} spacing={1.5}>
                <Grid item>
                  <div style={{
                    width: bulletSize,
                    height: bulletSize,
                    backgroundImage: `url('${BulletPointIcon}')`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'none'
                  }} />
                </Grid>
                <Grid item>
                  <Typography variant={'h4'}>{project.title}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant={'body1'}>
                <MuiMarkdown options={{forceWrapper: false}}>{project.description}</MuiMarkdown>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ProjectDetails;
